/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto-Medium.woff2') format('woff2'), url('/assets/fonts/Roboto-Medium.woff') format('woff'), url('/assets/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500; font-style: normal; font-display: swap;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto-Regular.woff2') format('woff2'), url('/assets/fonts/Roboto-Regular.woff') format('woff'), url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal; font-style: normal; font-display: swap;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto-Light.woff2') format('woff2'), url('/assets/fonts/Roboto-Light.woff') format('woff'), url('/assets/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300; font-style: normal; font-display: swap;
  }
  
  
  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto-Thin.woff2') format('woff2'), url('/assets/fonts/Roboto-Thin.woff') format('woff'), url('/assets/fonts/Roboto-Thin.ttf') format('truetype');
    font-weight: 100; font-style: normal; font-display: swap;
  }
  
  /* body {font-family: 'Roboto', sans-serif;} */
body {font-family: 'Roboto', sans-serif !important; color:#0A1C2B; background:#E7E7E7 !important;}

/* Overview Datatable Vertical Scroll bar style for compliance overview Datatable*/
#overviewDataTable .severity-status .dataTables_scrollBody::-webkit-scrollbar {
  height: 5px;
  width: 0px;
  cursor: pointer;
}

#overviewDataTable .severity-status .dataTables_scrollBody::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(255, 255, 255);
}

#overviewDataTable .severity-status .dataTables_scrollHeadInner {
  width: 100% !important;
}

.error_handling {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 99;
  text-align: center;
}

/* css for loader  */
.loader-container {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 99;
  height:100%;
  top: 0;
  left: 0;
}

.loader {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
}

.loader span {
  width: 40px;
  height: 40px;
  /*     background-color: #bbb; */
  border-radius: 50%;
  border: 5px solid #e7e7e7;
  border-top: 5px solid #0097ac;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0% {
      transform: rotate(0deg);
  }

  25% {
      transform: rotate(90deg);
  }

  50% {
      transform: rotate(180deg);
  }

  75% {
      transform: rotate(270deg);
  }

  100% {
      transform: rotate(360deg);
  }
}